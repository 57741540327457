<template>
  <n-grid cols="1 s:8" responsive="screen">
    <n-gi span="1 s:4" offset="0 s:1">
      <ChatContainer
        v-if="rent?.chatRoomId"
        :roomId="rent.chatRoomId"
        :readOnly="rent.state === 'ARBITRATION_IN_PROCESS'" /></n-gi
    ><n-gi span="0 s:2"
      ><div class="rent-resumen">
        <Header v-if="rent" :rent="rent" showTitleInH6 /><rent-resumen
          v-if="rent"
          :publicationTitle="rent.publication.title"
          :reservedDates="rent.requestedRangeOfDates"
          :deliveryInfo="rent.deliveryInfo"
          :executedDates="rent.executedRangeOfDates"
          :reservedDaysCount="rent.requestedDays"
          :executedDaysCount="rent.executedDays"
          :delayedDaysCount="
            rent.executedDays > rent.requestedDays
              ? rent.executedDays - rent.requestedDays
              : null
          "
          fullscreen
          fromMaintainer
        />
        <price-details
          v-if="rent"
          :rent="rent"
          fromMaintainer
          fullscreen
        /></div></n-gi></n-grid
  ><footer-mobile
    v-if="isMobile"
    haveDetails
    @expand="onExpandFooterMobileChange"
    :detailDrawerHeight="`${(parseInt(windowsHeight) - 80).toString()}px`"
    :showResume="false"
  >
    <div class="rent-resumen">
      <Header
        v-if="rent"
        :rent="rent"
        showTitleInH6
        :showDefaultImage="false"
        :bordered="false"
      /><rent-resumen
        v-if="rent"
        fromMaintainer
        :showPublicationTitle="false"
        fullscreen
        :rent="rent"
      />
      <price-details v-if="rent" :rent="rent" fromMaintainer fullscreen /></div
  ></footer-mobile>
</template>

<style lang="scss" scoped></style>

<script>
import { defineComponent, ref, onMounted, onUnmounted, computed } from "vue";
import { NGrid, NGi } from "naive-ui";
import responsive from "@/mixins/responsive";
import FooterMobile from "@/components/FooterMobile.vue";
import ChatContainer from "@/components/chat/ChatContainer.vue";
import Header from "@/components/rent/Header.vue";
import { useStore } from "vuex";
import RentResumen from "@/components/rent/Resumen.vue";
import PriceDetails from "@/components/rent/PriceDetails.vue";

export default defineComponent({
  name: "Chat",
  components: {
    NGrid,
    NGi,
    FooterMobile,
    ChatContainer,
    Header,
    RentResumen,
    PriceDetails,
  },
  mixins: [responsive],
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const store = useStore();
    const footerMobileExpanded = ref(false);
    const modelRef = computed(() => store.state.maintainer_rents.detail);

    const initChatRoom = async () => {
      store
        .dispatch("chat/createChatRoom", {
          rentId: props.id,
          roomName: modelRef.value.publication.title,
          users: [
            {
              _id: store.getters["auth/user_id"],
              username: store.state.user.userFromDB.name,
              isOnline: false,
            },
            {
              _id: modelRef.value.relatedUsers.filter(
                (user) => user != store.getters["auth/user_id"]
              )[0],
              username: (
                await store.dispatch(
                  "user/getUsernameById",
                  modelRef.value.relatedUsers.filter(
                    (user) => user != store.getters["auth/user_id"]
                  )[0]
                )
              ).name,
              isOnline: false,
            },
          ],
        })
        .then(async (chatRoomId) => {
          await store.dispatch("maintainer_rents/setChatRoom", chatRoomId);
        });
    };

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      store
        .dispatch("maintainer_rents/loadDetail", {
          id: props.id,
          userId: store.getters["auth/user_id"],
        })
        .then(async () => {
          if (modelRef.value && !modelRef.value.chatRoomId) {
            await initChatRoom();
          }
        });
      await store.dispatch("app/unlockUI");
    });
    onUnmounted(() => {
      store.dispatch("maintainer_rents/unloadDetail");
    });

    return {
      rent: modelRef,
      footerMobileExpanded,
      onExpandFooterMobileChange: (expanded) => (footerMobileExpanded.value = expanded),
    };
  },
});
</script>
