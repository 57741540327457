<template>
  <n-spin :show="busy">
    <vue-advanced-chat
      v-if="rooms"
      .current-user-id="user._id"
      .room-id="roomId"
      .rooms="rooms"
      .messages="messages"
      .messages-loaded="true"
      .rooms-loaded="`${roomsLoaded}`"
      @send-message="sendMessage"
      @open-file="openFile"
      .show-audio="false"
      .show-emojis="false"
      .show-footer="!chatReadOnly"
      .show-reaction-emojis="false"
      .single-room="true"
      .height="`${(parseInt(windowsHeight) - 160).toString()}px`"
      .styles="styles"
    />
  </n-spin>
</template>
<style lang="scss" scoped>
.vac-card-window {
  text-align: left;
  width: 99%;
}
:deep(.vac-media-preview) {
  width: 100%;
  height: 100%;
}
</style>

<script>
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Storage, PubSub } from "aws-amplify";
import { register } from "vue-advanced-chat";
import { formattedFiles, translateState, uuidv4 } from "./ChatUtils";
import { styles } from "./ChatStyle";
import { formatDates, formatAMPM, downloadBlob } from "@/shared/utils";
import responsive from "@/mixins/responsive";
import { useMessage, NSpin } from "naive-ui";
import { intervalToDuration, parseISO } from "date-fns";
import { useI18n } from "vue-i18n";

async function refillUrlFiles(newMessage) {
  if (newMessage.files?.length > 0) {
    for (const file of newMessage.files) {
      file.url = await Storage.get(file.key, {
        level: "protected",
        identityId: newMessage.senderId,
      });
    }
  }
}

export default defineComponent({
  name: "ChatContainer",
  components: {
    // ChatWindow,
    NSpin,
  },

  props: {
    roomId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [responsive],
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    register();
    const message = useMessage();
    const store = useStore();
    const router = useRouter();
    const messagesRef = ref([]);
    const busyRef = ref(false);
    const chatReadOnly = ref(props.readOnly);
    const userRef = computed(() => {
      return {
        _id: store.getters["auth/user_id"],
        username: store.state.user.userFromDB.name,
      };
    });
    const roomRef = ref(null);
    const socketSubscription = ref(null);
    //Flags
    const messagesLoadedRef = ref(false);
    const roomsLoadedRef = ref(false);
    const roomUsersRef = ref([]);

    const usersAreOnline = computed(() => {
      return roomUsersRef.value.filter((user) => user.isOnline).length > 0;
    });

    const loadContext = async () => {
      roomRef.value = await store.dispatch("chat/getChatRoom", props.roomId);
      for (const msgItem of roomRef.value.messages.items) {
        await refillUrlFiles(msgItem);
      }
      messagesRef.value = [...roomRef.value.messages.items];
    };

    const initWebSocket = () => {
      socketSubscription.value = PubSub.subscribe(roomRef.value.id).subscribe({
        next: (data) => {
          if (data.value.sender != userRef.value._id) {
            if ("newMessage" in data.value) {
              refillUrlFiles(data.value.newMessage).then(() => {
                messagesRef.value = [
                  ...messagesRef.value,
                  data.value.newMessage,
                ];
              });
            }
            if ("messageRead" in data.value) {
              messagesRef.value = messagesRef.value.map((message) =>
                message.id === data.value.messageRead
                  ? { ...message, seen: true, distributed: true }
                  : message
              );
            }
            if ("userUpdated" in data.value) {
              roomUsersRef.value = roomUsersRef.value.map((user) =>
                user._id === data.value.userUpdated._id
                  ? data.value.userUpdated
                  : user
              );
            }
          }
        },
        error: (error) => {
          console.error(error);
          message.error(t("chat.errors.connectionClosed"));
          router.push({ name: "my-rents" });
        },
      });
    };

    const sendImAliveSignal = () => {
      PubSub.publish(roomRef.value.id, {
        sender: userRef.value._id,
        userUpdated: {
          ...userRef.value,
          isOnline: true,
          updatedAt: new Date(),
        },
      });
    };

    const imAliveSignalThread = async () => {
      while (socketSubscription.value) {
        if (usersAreOnline.value) {
          sendImAliveSignal();
        }
        await new Promise((r) => setTimeout(r, 500));
      }
    };

    const checkUsersAvailability = async () => {
      while (socketSubscription.value) {
        roomUsersRef.value
          .filter(
            (user) =>
              user.isOnline && user.updatedAt && user._id != userRef.value._id
          )
          .forEach((user) => {
            const interval = intervalToDuration({
              start: parseISO(user.updatedAt),
              end: new Date(),
            });
            if (interval.seconds > 1) {
              user.isOnline = false;
            }
          });

        await new Promise((r) => setTimeout(r, 1000));
      }
    };

    const initRoomUsers = async () => {
      roomUsersRef.value.push(userRef.value);
      store
        .dispatch(
          "user/getUsernameById",
          roomRef.value.relatedUsers.filter(
            (user) => user != store.getters["auth/user_id"]
          )[0]
        )
        .then((user) => {
          roomUsersRef.value.push({
            _id: user.id,
            username: user.name,
          });
        });
    };

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      await loadContext();
      await initWebSocket();
      await initRoomUsers();
      checkUsersAvailability();
      imAliveSignalThread();
      sendImAliveSignal();
      messagesLoadedRef.value = true;
      roomsLoadedRef.value = true;
      await store.dispatch("app/unlockUI");
    });
    onUnmounted(async () => {
      if (socketSubscription.value) {
        await socketSubscription.value.unsubscribe();
        socketSubscription.value = null;
      }
    });

    const sendMessage = async ({ detail }) => {
      const content = detail[0].content;
      const files = detail[0].files;
      const date = new Date();
      const message = {
        content,
        relatedUsers: roomRef.value.relatedUsers,
        chatRoomId: roomRef.value.id,
        senderId: userRef.value._id,
        date: formatDates(date),
        timestamp: formatAMPM(date),
        indexId: messagesRef.value.length + 1,
        username: store.state.user.userFromDB.name,
        disableActions: true,
        disableReactions: true,
        ...translateState("SAVED"),
      };
      if (files) {
        message.files = formattedFiles(files);
      }

      if (files) {
        message.id = uuidv4();
        busyRef.value = true;
        for (let file of files) {
          const imgResult = await Storage.put(
            `chats/room/${props.roomId}/message/${message.id}/${file.name}`,
            file.blob,
            {
              level: "protected",
              identityId: store.getters["auth/user_id"],
              contentType: file.type,
            }
          );
          const currentFile = message.files.filter(
            (x) => (x.name = file.name)
          )[0];
          currentFile.key = imgResult.key;
          await refillUrlFiles(message);
        }
        busyRef.value = false;
      }
      store.dispatch("chat/saveMessage", message).then(async (response) => {
        await refillUrlFiles(response);
        messagesRef.value = [...messagesRef.value, response];
        const sender = roomUsersRef.value.filter(
          (user) => user._id === userRef.value._id
        )[0];
        const receiver = roomUsersRef.value.filter(
          (user) => user._id != userRef.value._id
        )[0];
        if (receiver.isOnline) {
          PubSub.publish(roomRef.value.id, {
            sender: userRef.value._id,
            newMessage: response,
          });
        } else {
          store.dispatch("notifications/FireNotification", {
            userId: receiver._id,
            fromUserId: sender._id,
            title: t("chat.notifications.newMessage", {
              user: sender.username,
            }),
            message:
              response.content.length === 0 && response.files.length > 0
                ? t("chat.notifications.fileReceived")
                : response.content,
            targetRoute: {
              name: "rent-chat",
              params: [
                {
                  key: "id",
                  value: roomRef.value.id,
                },
              ],
            },
          });
        }
      });
    };

    const openFile = async ({ message, file }) => {
      busyRef.value = true;
      const result = await Storage.get(file.file.key, {
        download: true,
        level: "protected",
        identityId: message.senderId,
      });
      downloadBlob(result.Body, file.file.name);
      busyRef.value = false;
    };

    const markMessageAsRead = (message) => {
      store
        .dispatch("chat/markMessageAsRead", {
          messageId: message.id,
          version: message._version,
        })
        .then(async (response) => {
          messagesRef.value = messagesRef.value.map((message) =>
            message.id === response.id ? response : message
          );
          if (usersAreOnline.value) {
            PubSub.publish(roomRef.value.id, {
              sender: userRef.value._id,
              messageRead: response.id,
            });
          }
        });
    };

    watch(messagesRef, async (newMessagesList) => {
      const unreadMessages = newMessagesList.filter(
        (message) => message.senderId != userRef.value._id && !message.seen
      );
      unreadMessages.forEach((unreadMessage) => {
        markMessageAsRead(unreadMessage);
      });
    });

    return {
      t,
      messagesLoaded: messagesLoadedRef,
      roomsLoaded: roomsLoadedRef,
      rooms: computed(() =>
        roomRef.value
          ? [
              {
                ...roomRef.value,
                roomId: roomRef.value.id,
                roomName: roomRef.value.users.filter(
                  (user) => user._id !== store.getters["auth/user_id"]
                )[0].username,
                users: roomUsersRef.value.map((user) => {
                  return {
                    ...user,
                    status: { state: user.isOnline ? "online" : "offline" },
                  };
                }),
              },
            ]
          : null
      ),
      messages: computed(() =>
        messagesRef.value
          ?.map((message) => {
            return { ...message, _id: message.id };
          })
          .sort((a, b) => {
            if (a.indexId > b.indexId) {
              return 1;
            }
            if (a.indexId < b.indexId) {
              return -1;
            }
            return 0;
          })
      ),
      user: userRef,
      sendMessage,
      openFile,
      styles,
      roomRef,
      roomUsersRef,
      busy: busyRef,
      chatReadOnly,
    };
  },
});
</script>

<style lang="scss" scoped></style>
