const formattedFiles = (files) => {
    const formatted = []
    files.forEach(file => {
        const messageFile = {
            name: file.name,
            size: file.size,
            type: file.type,
            extension: file.extension || file.type,
            url: file.url || file.localUrl
        }
        if (file.audio) {
            messageFile.audio = true
            messageFile.duration = file.duration
        }
        formatted.push(messageFile)
    })
    return formatted
}

const translateState = (state) => {
    switch (state) {
        case 'CREATED':
            return {
                saved: false,
                distributed: false,
                seen: false,
                deleted: false,
                failure: false,
                system: false
            }
        case 'SAVED':
            return {
                saved: true,
                distributed: false,
                seen: false,
                deleted: false,
                failure: false,
                system: false
            }
        
        case 'SENT':
            return {
                saved: true,
                distributed: true,
                seen: false,
                deleted: false,
                failure: false,
                system: false
            } 
        case 'READ':
            return {
                saved: true,
                distributed: true,
                seen: true,
                deleted: false,
                failure: false,
                system: false
            } 
        case 'FAILED':
        default:
            return {
                saved: false,
                distributed: false,
                seen: false,
                deleted: false,
                failure: true,
                system: false
            }
    }
}


const uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
  

export { formattedFiles, uuidv4, translateState };