import { themeOverrides } from "@/shared/constants";

const styles = {
    general: {
        color: themeOverrides.common.darkColor,
        colorButtonClear: themeOverrides.common.successColor,
        colorButton: themeOverrides.common.whiteColor,
        backgroundColorButton: themeOverrides.common.successColor,
        backgroundInput: themeOverrides.common.whiteColor,
        colorPlaceholder: themeOverrides.common.borderChatColor,
        colorCaret: themeOverrides.common.successColor,
        colorSpinner: themeOverrides.common.spinnerColor,
        borderStyle: `0px solid ${themeOverrides.common.borderChatColor}`,
        backgroundScrollIcon: themeOverrides.common.whiteColor
    },

    container: {
        border: `1px solid ${themeOverrides.common.successColor}`,
        borderRadius: '1px',
        boxShadow: '0px'
    },

    header: {
        background: themeOverrides.common.whiteColor,
        colorRoomName: themeOverrides.common.darkColor,
        colorRoomInfo: themeOverrides.common.borderChatColor
    },

    footer: {
        background: themeOverrides.common.whiteColor,
        borderStyleInput: `1px solid ${themeOverrides.common.borderStyleInput}`,
        borderInputSelected: themeOverrides.common.successColor,
        backgroundReply: themeOverrides.common.tagActiveColor,
        backgroundTagActive: themeOverrides.common.tagActiveColor,
        backgroundTag: themeOverrides.common.contentColor
    },

    content: {
        background: themeOverrides.common.contentColor
    },

    sidemenu: {
        background: themeOverrides.common.whiteColor,
        backgroundHover: themeOverrides.common.disabledColor,
        backgroundActive: themeOverrides.common.backgroundActiveColor,
        colorActive: themeOverrides.common.successColor,
        borderColorSearch: themeOverrides.common.borderColorSearch
    },

    dropdown: {
        background: themeOverrides.common.whiteColor,
        backgroundHover: themeOverrides.common.disabledColor
    },

    message: {
        background: themeOverrides.common.whiteColor,
        backgroundMe: themeOverrides.common.backgroundMeColor,
        color: themeOverrides.common.darkColor,
        colorStarted: themeOverrides.common.borderChatColor,
        backgroundDeleted: themeOverrides.common.backgroundDeleteColor,
        backgroundSelected: themeOverrides.common.backgroundSelectedColor,
        colorDeleted: themeOverrides.common.deleteColor,
        colorUsername: themeOverrides.common.borderChatColor,
        colorTimestamp: themeOverrides.common.timeStampMessageColor,
        backgroundDate: themeOverrides.common.backgroundActiveColor,
        colorDate: themeOverrides.common.dateMessageColor,
        backgroundSystem: themeOverrides.common.backgroundActiveColor,
        colorSystem: themeOverrides.common.dateMessageColor,
        backgroundMedia: themeOverrides.common.backgroundMedia,
        backgroundReply: themeOverrides.common.backgroundReply,
        colorReplyUsername: themeOverrides.common.darkColor,
        colorReply: themeOverrides.common.replyColor,
        colorTag: themeOverrides.common.tagColor,
        backgroundImage: themeOverrides.common.borderColor,
        colorNewMessages: themeOverrides.common.successColor,
        backgroundScrollCounter: themeOverrides.common.badgeColor,
        colorScrollCounter: themeOverrides.common.whiteColor,
        backgroundReaction: themeOverrides.common.backgroundReactionColor,
        borderStyleReaction: `1px solid ${themeOverrides.common.backgroundReactionColor}`,
        backgroundReactionHover: themeOverrides.common.whiteColor,
        borderStyleReactionHover: `1px solid ${themeOverrides.common.borderColor}`,
        colorReactionCounter: themeOverrides.common.darkColor,
        backgroundReactionMe: themeOverrides.common.backgroundReactionMeColor,
        borderStyleReactionMe: `1px solid ${themeOverrides.common.borderStyleReactionMe}`,
        backgroundReactionHoverMe: themeOverrides.common.backgroundReactionMeColor,
        borderStyleReactionHoverMe: `1px solid ${themeOverrides.common.borderStyleReactionMe}`,
        colorReactionCounterMe: themeOverrides.common.reactionCounterMe,
        backgroundAudioRecord: themeOverrides.common.autoCancelColor,
        backgroundAudioLine: themeOverrides.common.backgroundMedia,
        backgroundAudioProgress: themeOverrides.common.autoPlayColor,
        backgroundAudioProgressSelector: themeOverrides.common.autoPlayColor,
        colorFileExtension: themeOverrides.common.deleteColor
    },

    markdown: {
        background: themeOverrides.common.grayColor,
        border: themeOverrides.common.borderLightColor,
        color: themeOverrides.common.markdownColor,
        colorMulti: themeOverrides.common.darkColor
    },

    room: {
        colorUsername: themeOverrides.common.darkColor,
        colorMessage: themeOverrides.common.messageColor,
        colorTimestamp: themeOverrides.common.timeStampColor,
        colorStateOnline: themeOverrides.common.stateColor,
        colorStateOffline: themeOverrides.common.borderChatColor,
        backgroundCounterBadge: themeOverrides.common.badgeColor,
        colorCounterBadge: themeOverrides.common.whiteColor
    },

    emoji: {
        background: themeOverrides.common.whiteColor
    },

    icons: {
        search: themeOverrides.common.borderChatColor,
        add: themeOverrides.common.successColor,
        toggle: themeOverrides.common.darkColor,
        menu: themeOverrides.common.darkColor,
        close: themeOverrides.common.borderChatColor,
        closeImage: themeOverrides.common.whiteColor,
        file: themeOverrides.common.successColor,
        paperclip: themeOverrides.common.successColor,
        closeOutline: themeOverrides.common.blackColor,
        closePreview: themeOverrides.common.whiteColor,
        send: themeOverrides.common.successColor,
        sendDisabled: themeOverrides.common.borderChatColor,
        emoji: themeOverrides.common.successColor,
        emojiReaction: themeOverrides.common.emojiReaction,
        document: themeOverrides.common.successColor,
        pencil: themeOverrides.common.grayMediumColor,
        checkmark: themeOverrides.common.grayMediumColor,
        checkmarkSeen: themeOverrides.common.badgeColor,
        eye: themeOverrides.common.whiteColor,
        dropdownMessage: themeOverrides.common.whiteColor,
        dropdownMessageBackground: themeOverrides.common.dropdownMessageBackground,
        dropdownRoom: themeOverrides.common.grayMediumColor,
        dropdownScroll: themeOverrides.common.darkColor,
        microphone: themeOverrides.common.successColor,
        audioPlay: themeOverrides.common.autoPlayColor,
        audioPause: themeOverrides.common.autoPlayColor,
        audioCancel: themeOverrides.common.autoCancelColor,
        audioConfirm: themeOverrides.common.autoConfirmColor
    }
  }

  export { styles };
