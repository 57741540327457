<template>
  <n-card :bordered="bordered"
    ><n-page-header>
      <n-grid :cols="stats?.length">
        <n-gi v-for="stat in stats" :key="stat.label">
          <n-statistic :label="stat.label" :value="stat.value" />
        </n-gi>
      </n-grid>
      <template #title>
        <a @click="goToPublication(rent.publication.id)">
          <n-h6 v-if="showTitleInH6">{{ rent.publication.title }} </n-h6>
          <n-h1 v-else>{{ rent.publication.title }} </n-h1></a
        >
      </template>

      <template #extra>
        <publication-resumen
          v-if="rent.publication"
          :publication="rent.publication"
          :bordered="false"
          :showDefaultImage="showDefaultImage"
          :showTitle="false"
        />
      </template> </n-page-header
  ></n-card>
</template>

<style lang="scss" scoped>
.n-card *,
:deep(.n-statistic .n-statistic-value .n-statistic-value__content) {
  @media screen and (max-width: 799px) {
    font-size: 16px;
  }
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>

<script>
import { onMounted, toRefs, computed } from "vue";
import { NGrid, NGi, NCard, NPageHeader, NStatistic, NH1, NH6 } from "naive-ui";
import PublicationResumen from "@/components/publication/PublicationResumen.vue";
import { useRouter } from "vue-router";
import { differenceInCalendarDays } from "date-fns";
import { useI18n } from "vue-i18n";

export default {
  name: "Header",
  props: {
    rent: { type: Object, required: true },
    showTitleInH6: { type: Boolean, default: false },
    showDefaultImage: { type: Boolean, default: true },
    bordered: { type: Boolean, default: true },
    hideState: { type: Boolean, default: false },
  },
  components: {
    NGrid,
    NGi,
    NCard,
    NPageHeader,
    NStatistic,
    NH1,
    NH6,
    PublicationResumen,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const rentRef = toRefs(props).rent;

    onMounted(() => {});

    return {
      stats: computed(() => {
        const tmpStats = [];
        if (!props.hideState) {
          tmpStats.push({
            label: t("rent.detail.header.labels.rentStatus"),
            value: t("rent.states." + rentRef.value.state),
          });
        }

        tmpStats.push({
          label: t("rent.detail.header.labels.reserved"),
          value: `${rentRef.value.requestedDays} ${t(
            "commons.labels.day",
            rentRef.value.requestedDays
          )}`,
        });
        if (rentRef.value.executedDays) {
          tmpStats.push({
            label: t("rent.detail.header.labels.executed"),
            value: `${rentRef.value.executedDays} ${t(
              "commons.labels.day",
              rentRef.value.executedDays
            )}`,
          });
        }
        const delayedDays = differenceInCalendarDays(
          rentRef.value.executedRangeOfDates?.end || new Date(),
          rentRef.value.requestedRangeOfDates.end
        );
        if (delayedDays > 0) {
          tmpStats.push({
            label: t("rent.detail.header.labels.extra"),
            value: `${delayedDays} ${t("commons.labels.day", delayedDays)}`,
          });
        }
        return tmpStats;
      }),
      goToPublication(id) {
        router.push({ name: "view-publication", params: { publicationId: id } });
      },
    };
  },
};
</script>
